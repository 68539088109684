<template>
  <LayoutHeader></LayoutHeader>
  <router-view></router-view>
  <LayoutFooter></LayoutFooter>
</template>

<script>
import LayoutHeader from "@/views/layout/layout-base-header";
import LayoutFooter from "@/views/layout/layout-base-footer";

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
  },

  setup() {},
};
</script>

<style>
</style>
