<template>
  <!--/w3l-footer-29-main-->
  <footer class="w3l-footer-29-main">
    <div class="footer-29 py-5">
      <div class="container py-lg-4">
        <div class="row footer-top-29 mt-md-5">
          <div class="col-lg-4 col-md-6 footer-list-29 footer-3 pe-lg-5">
            <h6 class="footer-title-29">Links</h6>
            <div class="footer-listw3-grids">
              <ul class="footer-listw3">
                <li><a href="/">Home</a></li>
                <li><a href="/produtos">Produtos</a></li>
                <li><a href="/marcas">Marcas</a></li>
                <li><a href="/sobre">Sobre</a></li>
                <!-- <li><a href="blog.html">Blogs</a></li>
                <li><a href="contact.html">Contact</a></li>
                <li><a href="index.html#support">Support</a></li>
                <li><a href="index.html#news">News & Media</a></li>
                <li><a href="index.html#Careers">Careers</a></li> -->
              </ul>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 footer-list-29 footer-1 ps-lg-5 mt-lg-0 mt-md-5 mt-4"
          >
            <!-- <h6 class="footer-title-29">Information</h6>
            <div class="footer-listw3-grids">
              <ul class="footer-listw3">
                <li><a href="index.html#terms">Terms & Conditions</a></li>
                <li><a href="index.html#deivery">Delivery Terms</a></li>
                <li><a href="index.html#order">Order Tracking</a></li>
                <li><a href="index.html#returns">Returns Policy</a></li>
                <li><a href="index.html#support">Privacy Policy</a></li>
                <li><a href="index.html#faq">FAQ</a></li>
                <li><a href="index.html#shop">The Shop</a></li>
              </ul>
            </div> -->
          </div>
          <div
            class="col-lg-4 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-md-5 mt-4 pe-lg-5"
          >
            <h6 class="footer-title-29">Endereço</h6>
            <div class="footer-listw3-grids">
              <ul class="footer-listw3">
                <li>Rua Quarai, 980</li>
                <li>Imigrante</li>
                <li>Campo Bom</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row bottom-copies">
          <p class="copy-footer-29 col-lg-8">
            © 2022 Eco Latas Retalhos Automotivos
          </p>
          <div class="col-lg-4 main-social-right mt-lg-0 mt-4">
            <div class="main-social-footer-29">
              <a :href="socialFacebook" class="facebook"
                ><span class="fab fa-facebook-f"></span
              ></a>
              <a :href="socialInstagram" class="instagram"
                ><span class="fab fa-instagram"></span
              ></a>
              <a :href="socialWhatspp" class="whatsapp" target="_blank"
                ><span class="fab fa-whatsapp"></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- move top -->
    <button onclick="topFunction()" id="movetop" title="Go to top">
      <span class="fa fa-angle-up"></span>
    </button>
    <!-- <script>
            // When the user scrolls down 20px from the top of the document, show the button
            window.onscroll = function() {
                scrollFunction()
            };

            function scrollFunction() {
                if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                    document.getElementById("movetop").style.display = "block";
                } else {
                    document.getElementById("movetop").style.display = "none";
                }
            }

            // When the user clicks on the button, scroll to the top of the document
            function topFunction() {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }

        </script> -->
    <!-- //move top -->
  </footer>
  <!-- <footer>
    footer....
  </footer> -->
</template>

<script>

export default {
  setup() {
    return {
      socialFacebook: `${process.env.VUE_APP_URL_SOCIAL_FACEBOOK}`,
      socialInstagram: `${process.env.VUE_APP_URL_SOCIAL_INSTAGRAM}`,
      socialWhatspp: `${process.env.VUE_APP_URL_SOCIAL_WHATSAPP}`,
    };
  },
};
</script>

<style></style>
